
import { defineComponent } from "@vue/runtime-core";
import ExtensionCard from "@/components/ExtensionCard.vue";

export default defineComponent({
  data: () => ({
    choosedGrade: 'Бакалавриат',
    choosedCard: -1,
    grades: [
      {
        name: "Бакалавриат",
        directions: [
          {
            name: "09.03.02 Информационные системы и технологии",
            budgetPlaces: 115,
            contractPlaces: 120,
            programs: [
              "Большие данные и машинное обучение",
              "Компьютерные науки и технологии искусственного интеллекта",
              "Фуллстек разработка",
              "Системная аналитика и разработка программного обеспечения"
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 310 350",
            exams: [
              "Математика",
              "Информатика и ИКТ, физика",
              "Русский язык",
            ],
            spoExams: [
              "Информационные технологии",
              "Архитектура аппаратных средств",
              "Русский язык",
            ],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Благодаря полученным навыкам и компетенциям, выпускники могут работать специалистами интеллектуального анализа данных (Data Mining Specialists), инженерами данных (Data Engineers), разработчиками баз данных (Database Developers), программистами, системными аналитиками, бизнес- аналитиками (Business Analysts, BI Analysts), DevOps-инженерами, руководителями проектов, Web-разработчиками, фуллстек (Full Stack), фронтенд (Frontend) или бэкенд (Backend)- разработчиками.",
          },
          {
            name: "09.03.03 Прикладная информатика",
            budgetPlaces: 100,
            contractPlaces: 60,
            programs: [
              "Системы искусственного интеллекта",
              "Программные решения для бизнеса",
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 310 350",
            exams: [
              "Математика",
              "Информатика и ИКТ, физика",
              "Русский язык",
            ],
            spoExams: [
              "Информационные технологии",
              "Архитектура аппаратных средств",
              "Русский язык",
            ],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники данной могут претендовать на такие престижныепрофессии как менеджер проектов в области информационных технологий, аналитик бизнес-процессов, системный аналитик, бизнес-консультант по информационным технологиям, архитектор информационных систем, аналитик данных, специалист по машинному обучению, инженер по данным, дата-сайентист, инженер-исследователь, разработчик программного обеспечения.",
          },
          {
            name: "38.03.01 Экономика",
            budgetPlaces: 0,
            contractPlaces: "60 очная форма обучения / 90 очно-заочная форма обучения",
            programs: [
              "Бизнес-архитектура, учет и финансы (очная и очно-заочная формы)",
              "Экономика цифрового предприятия (очная и очно-заочная формы)",
              "Организация бизнес-процессов предприятия (очно-заочная форма)"
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "Математика",
              "Обществознание или История или География или Информатика и ИКТ или Иностранный язык (английский)",
              "Русский язык",
            ],
            spoExams: ["Экономика организации", "Менеджмент", "Русский язык"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Профессиональная деятельность выпускника возможна в экономических, финансовых, маркетинговых, производственно- экономических и аналитических службах организаций различных отраслей (в том числе и в аграрном секторе экономики), сферах и формах собственности, кредитных и страховых учреждениях, органах государственной и муниципальной власти, академических и ведомственных научно-исследовательских организациях, учреждениях высшего профессионального образования.",
          },
          {
            name: "38.03.02 Менеджмент",
            budgetPlaces: 0,
            contractPlaces: 60,
            programs: [
              "Бизнес-логистика и маркетинг",
              "Управление бизнесом",
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "Математика",
              "Обществознание или История или Информатика и ИКТ или Иностранный язык (английский)",
              "Русский язык",
            ],
            spoExams: ["Экономика организации", "Менеджмент", "Русский язык"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники бакалавриата могут продолжить обучение в магистратуре, в том числе по индивидуальному графику совмещая учебу с работой специалистами банков и инвестиционных фондов, управляющими подразделений и специалистами государственных органов управления. Выпускники востребованы в крупных организациях агропромышленного комплекса, агрохолдингах, отечественных и иностранных компаниях, научно-исследовательских институтах."
          },
          {
            name: "38.03.04\tГосударственное и муниципальное управление",
            budgetPlaces: 0,
            contractPlaces: 25,
            programs: ["Муниципальное управление"],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "Математика",
              "Обществознание или История или География или Информатика и ИКТ или Иностранный язык (английский)",
              "Русский язык",
            ],
            spoExams: ["Экономика организации", "Менеджмент", "Русский язык"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники могут работать специалистами и руководителями в области государственного и муниципального управления, а также специалистами по государственному и муниципальному управлению в частных компаниях или неправительственных организациях, аналитиками в государственных или муниципальных органах, консультантами по вопросам государственного и муниципального управления в консалтинговых компаниях и общественных организациях.",
          },
          {
            name: "42.03.01 Реклама и связи с общественностью",
            budgetPlaces: 0,
            contractPlaces: 60,
            programs: [
              "Реклама и медиакоммуникации",
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "Обществознание",
              "История, Информатика и информационно-коммуникационные технологии (ИКТ) Иностранный язык (английский), Литература",
              "Русский язык"
            ],
            spoExams: ["Русский язык", "Экономика организации", "Менеджмент"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники бакалавриата могут продолжить обучение в магистратуре, в том числе по индивидуальному графику совмещая учебу с работой, востребованы в крупных коммуникационных агентствах полного цикла, отделах по связям с общественностью в крупных компаниях и организациях, в пресс-службах и редакциях различных медиахолдингов."
          },
          {
            name: "43.03.02 Туризм",
            budgetPlaces: 0,
            contractPlaces: 25,
            programs: ["Туризм и индустрия гостеприимства"],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "История",
              "География, Обществознание, Биология; Иностранный язык (английский)",
              "Русский язык"
            ],
            spoExams: ["Русский язык", "Психология", "Экономика организации"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники бакалавриата могут продолжить обучение в магистратуре, в том числе по индивидуальному графику совмещая учебу с работой, востребованы в крупных агрохолдингах, отечественных и иностранных компаниях, малых формах бизнеса и в государственных органах.",
          },
          {
            name: "44.03.04 Профессиональное обучение (по отраслям)",
            budgetPlaces: 75,
            contractPlaces: "60 очная форма обучения / 30 заочная форма обучения",
            programs: [
              "Экономика и управление (очная и заочная формы)",
              "Информационные системы и технологии (очная форма)",
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 276 900",
            exams: [
              "Математика",
              "Информатика и информационно-коммуникационные технологии (ИКТ), История, Обществознание, Физика, Химия, География, Иностранный язык (английский), Биология, Литература",
              "Русский язык",
            ],
            spoExams: ["Педагогика", "Психология", "Русский язык"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники готовы к преподавательской деятельности по программам профессионального обучения, среднего профессионального образования и дополнительным профессиональным программам, востребованы в образовательных учреждениях, ресурсных центрах, компаниях по производству обучающего контента."
          },
        ],
      },
      {
        name: "Специалитет",
        directions: [
          {
            name: "38.05.01 Экономическая безопасность",
            budgetPlaces: 0,
            contractPlaces: "30 очная форма обучения / 30 заочная форма обучения",
            programs: [
              "Экономико-правовое обеспечение экономической безопасности (очная и заочная формы)",
            ],
            price: "данные на приемную кампанию 2025 Cледите за актуальной информацией на сайте университета в разделе  «Поступающим» Стоимость обучения без предоставления скидки: 277 000",
            exams: [
              "Математика",
              "Обществознание, История, Информатика и информационно-коммуникационные технологии (ИКТ)",
              "Русский язык",
            ],
            spoExams: ["Экономика организации", "Менеджмент", "Русский язык"],
            discounts: [
              "от 190 баллов – 30%",
              "от 211 балла – 40%",
              "от 231 балла – 50%",
            ],
            perspective: "Выпускники широко востребованы в отечественных и иностранных предприятиях в сельском хозяйстве, перерабатывающей промышленности и других отраслях экономики, банках, аудиторских компаниях. Выпускники данной программы специалитета также могут продолжить обучение в аспирантуре по научной специальности 5.2.3 Региональная и отраслевая экономика."
          },
        ],
      },
      {
        name: "Магистратура",
        directions: [
          {
            name: "09.04.02 Информационные системы и технологии",
            budgetPlaces: "21 очное / 0 заочное обучение",
            contractPlaces: "15 очное / 15 заочное обучение",
            programs: [
              "Науки о данных",
            ],
            price: "332 200 очное / 107 600 заочное обучение",
            exams: [
              "09.04.02 Информационные системы и технологии",
            ],
            discounts: [
              "от 70 баллов – 35% (скидка распространяется только на очное обучение)",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний",
            perspective: "Выпускники магистратуры могут работать специалистами по работе с данными (Data Scientists), аналитиками данных (Data Analysts), специалистами по информационным системам (Information Systems Specialists), специалистами машинного обучения (Machine Learning Specialists), программистами, руководителями проектов. Выпускники магистратуры могут продолжить обучение в аспирантуре по направлению 2.3.8 Информатика и информационные процессы."
          },
          {
            name: "09.04.03 Прикладная информатика",
            budgetPlaces: 0,
            contractPlaces: 30,
            budgetPlaces2: 20,
            contractPlaces2: 15,
            programs: [
              "Цифровое профилирование предприятий", 
              "Цифровая трансформация бизнеса",
            ],
            programs2: [
              "Архитектура систем искусственного интеллекта",
            ],
            price: "332 200",
            exams: [
              "09.04.03 Прикладная информатика",
            ],
            discounts: [
              "от 70 баллов – 35%",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний",
            perspective: "Выпускник может претендовать на высококвалифицированные должности в области разработки и внедрения систем искусственного интеллекта, анализа данных и машинного обучения. Трудоустройство возможно в IT-компаниях, финансовых учреждениях, промышленных предприятиях, научно-исследовательских институтах, в области здравоохранения, в логистических и транспортных компаниях, в розничной торговле и электронной коммерции, в телекоммуникациях, а также в государственных органах управления и сфере обеспечения национальной безопасности. Кроме того, выпускник может заниматься разработкой интеллектуальных систем поддержки принятия решений, систем автоматизации бизнес-процессов, аналитических систем для работы с большими данными и проектированием архитектур ИИ для комплексных сервисных платформ. Есть возможности для продолжения научной деятельности, включая аспирантуру и докторантуру, с целью проведения исследований в области искусственного интеллекта и разработки новых технологий."
          },
          {
            name: "38.04.01 Экономика",
            budgetPlaces: "5 очное / 3 заочное",
            contractPlaces: "10 очное / 12 заочное",
            budgetPlaces2: "5 очное / 2 заочное",
            contractPlaces2: "10 очное / 13 заочное",
            programs: [
              "Оценка бизнеса, корпоративный учет и финансы в цифровой экономике",
              
            ],
            programs2: [
            "Экономика устойчивого развития и ESG -трансформация бизнеса",
            ],
            price: "297 400 очное / 102 400 заочное обучение",
            exams: [
              "38.04.01 Экономика"
            ],
            discounts: [
              "от 70 баллов – 35% (скидка распространяется только на очное обучение)",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний 30 баллов.",
            perspective: "Перспективы трудоустройства, профессиональной и/или научной деятельности. Руководители организаций, заместители руководителей по экономическим вопросам и инновационному развитию, ведущие специалисты в государственных учреждениях, организациях АПК, высшие учебные заведения. Возможные профессии, сферы деятельности: экономические, финансовые, маркетинговые, производственно-экономические и аналитические службы организаций различных отраслей, сфер и форм собственности, органы государственной и муниципальной власти, академические и ведомственные научно-исследовательские организации, учреждения высшего профессионального образования."
          },
          {
            name: "38.04.02 Менеджмент",
            budgetPlaces: "0 очное / 5 заочное обучение",
            contractPlaces: "15 очное / 10 заочное обучение",
            programs: [
              "Стратегическое управление",
            ],
            price: "297 400 очное / 102 400 заочное обучение",
            exams: [
              "38.04.02 Менеджмент",
            ],
            discounts: [
              "от 70 баллов – 35% (скидка распространяется только на очное обучение)",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний",
            perspective: "Выпускники магистратуры имеют возможность продолжить обучение в аспирантуре. Выпускники работают специалистами банков и инвестиционных фондов, управляющими подразделений и специалистами государственных органов управления; востребованы в крупных агрохолдингах, научно-исследовательских организациях."
          },
          {
            name: "44.04.04 Профессиональное обучение (по отраслям)",
            budgetPlaces: "31 очное / 15 заочное обучение",
            contractPlaces: "15 очное / 10 заочное обучение",
            programs: [
              "Цифровая образовательная среда и цифровые технологии",
            ],
            price: "297 400 очное / 102 400 заочное обучение",
            exams: [
              "44.04.04 Профессиональное обучение",
            ],
            discounts: [
              "от 70 баллов – 35% (скидка распространяется только на очное обучение)",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний",
            perspective: "Выпускники по данной программе магистратуры востребованы в образовательных организациях разного уровня, а также в методических и ресурсных центрах, организациях дополнительного профессионального образования, научно-исследовательских институтах, учебно-курсовых комбинатах и производственных предприятиях. Выпускники программы магистратуры также могут продолжить обучение в аспирантуре по научной специальности 5.8.7 Методология и технология профессионального образования."
          },
          {
            name: "38.04.04 Государственное и муниципальное управление",
            budgetPlaces: "5 заочное обучение",
            contractPlaces: "10 заочное обучение",
            programs: [
              "Государственная служба",
            ],
            price: "102 400 заочное обучение",
            exams: [
              "38.04.04 Государственное и муниципальное управление",
            ],
            discounts: [
              "Скидки нет",
            ],
            testForm: "бланковое или компьютерное тестирование Минимальное количество баллов для вступительных испытаний",
            perspective: "Выпускники магистратуры имеют возможность продолжить обучение в аспирантуре. Выпускники работают специалистами сферы публичного управления, системы государственного и муниципального управления, менеджерами организаций, взаимодействующих с органами государственного и муниципального управления."
          },
          {
            name: "42.04.01 Реклама и связи с общественностью",
            budgetPlaces: "0",
            contractPlaces: "15",
            programs: [
              "Реклама и медиакоммуникации в цифровой среде",
            ],
            price: "297 400",
            exams: [
              "42.04.01 Реклама и связи с общественностью",
            ],
            discounts: [
              "от 70 баллов – 35%",
            ],
            testForm: "Конкурсный отбор (портфолио)",
            perspective: "Выпускники магистратуры востребованы в крупных коммуникационных агентствах полного цикла, отделах по связям с общественностью в крупных компаниях и организациях, в пресс-службах и редакциях различных медиахолдингов."
          },
        ],
      },
    ],
  }),
  methods: {
    chooseGrade(gradeName : string) {
      this.choosedCard = -1
      this.choosedGrade = gradeName
    },
    changeState(index : number) {
      if (this.choosedCard === index) {
        this.choosedCard = -1
      }
      else {
        this.choosedCard = index
      }
    }
  },
  components: { ExtensionCard },
});
